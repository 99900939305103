import React from "react"
import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Connecticut Location Directions | Bartlett & Grippe ",
    description:
      "Get directions to Bartlett & Grippe, LLC's two centrally located office locations in Cheshire, Connecticut. We offer phone and video consultations with secure document transfer.",
    heroH1: "Directions to Bartlett & Grippe, LLC",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">Directions to Bartlett & Grippe, LLC</h2>
            <h3 className="uppercase mb-16">
              We have two office locations centrally located in Cheshire,
              Connecticut
            </h3>

            <div className="mb-16">
              <h3 className="uppercase mb-0">Main Street Office</h3>
              <p>143 Main St.</p>
              <p>Cheshire, Connecticut 06410</p>
              <p>
                <strong>Phone:</strong> <a href="tel:+1-203-272-1157" class="bg-phone-number"><span class="bg-phone-number">203-272-1157</span></a> 
              </p>
              <p className="mb-4">
                <strong>Fax:</strong> 203-250-1835
              </p>
              <p className="mb-4">
                <em>
                  To get directions to this location, click "Directions" or
                  "View larger map" on the map below (Google Maps will open in a
                  new window).
                </em>
              </p>
              <iframe
                title="Map of 143 Main St."
                className="w-full border-0"
                width="600"
                height="450"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5976.168042148678!2d-72.903583!3d41.502454!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7c603e1ff2469%3A0x6487494b59175b6e!2s143%20Main%20St%2C%20Cheshire%2C%20CT%2006410!5e0!3m2!1sen!2sus!4v1656631223209!5m2!1sen!2sus"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="mb-16">
              <h3 className="uppercase mb-0">Wallingford Road Office</h3>
              <p>36 Wallingford Rd.</p>
              <p>Cheshire, Connecticut 06410</p>
              <p>
                <strong>Phone:</strong> <a href="tel:+1-203-463-4939" class="bg-phone-number"><span class="bg-phone-number">203-463-4939</span></a> 
              </p>
              <p className="mb-4">
                <strong>Fax:</strong> 203-439-7730
              </p>
              <p className="mb-4">
                Handicapped parking is available next to our office. Additional
                parking is also available behind our offices via Elm St.
              </p>
              <p className="mb-4">
                <em>
                  To get directions to this location, click "Directions" or
                  "View larger map" on the map below (Google Maps will open in a
                  new window).
                </em>
              </p>
              <iframe
                title="Map of 36 Wallingford Rd."
                className="w-full border-0"
                width="600"
                height="450"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2988.335825177996!2d-72.9028183842707!3d41.49699679715544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7c5ffd8d4c5eb%3A0x7c6e5e5f5a6173bb!2s36%20Wallingford%20Rd%2C%20Cheshire%2C%20CT%2006410!5e0!3m2!1sen!2sus!4v1656631378958!5m2!1sen!2sus"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <p className="mb-8">
              Because of the Covid 19 pandemic we have upgraded our technology
              to better serve you and your family. We can now also meet with you
              via phone or video conference. You can securely transfer
              documents, and you can even sign documents electronically from the
              comfort of your home computer or a device such as an iPad or smart
              phone. We are here to serve your serious injury legal needs or{" "}
              <Link to="/ct-wrongful-death/">
                help you with filing a claim concerning the death of a loved one
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
